
export class Constants {
  public static readonly APP_NAME = "Voltau"
  public static readonly REGEX_PERCENTAGE_NUMBER = /\b([0-9]|[1-9][0-9]|100)\b/;
  public static readonly URL_PATTERN = /^(?:https?|wss?):\/\/((?:[\w-]+)(?:\.[\w-]+)*)(?:[\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?$/;

  public static readonly CSV_SEPARATOR = ',';
  public static readonly CR_LF = '\r\n';

  public static readonly BILLING_MAX_PAYMENT_METHODS = 2;

  public static readonly TRANSACTION_LOG_TIME_FRAME_MINS = 5;

  public static readonly MAX_PAGE_SIZE = 1000;
  public static readonly AVG_PAGE_SIZE = 250;
  public static readonly DEFAULT_PAGE_SIZE = 50;
  public static readonly INFINITE_RECORDS = -1;

  public static readonly SMART_CHARGING_DEFAULT_DEPARTURE_TIME_MINS = 120;
  public static readonly SMART_CHARGING_DEFAULT_INITIAL_SOC = 25;
  public static readonly SMART_CHARGING_DEFAULT_TARGET_SOC = 85;

  public static readonly REFRESH_GUEST_USER_TRANSACTION_STARTED_MILLIS = 10 * 1000;
  public static readonly REFRESH_GUEST_CHECK_CHARGING_STATION_MILLIS = 15 * 1000;
  public static readonly REFRESH_GUEST_USER_TRANSACTION_MILLIS = 30 * 1000;
  public static readonly REFRESH_CHECK_UNPAID_INVOICES_MILLIS = 60 * 1000;

  public static readonly MAX_LIMIT = Number.MAX_SAFE_INTEGER;

  public static readonly REST_RESPONSE_SUCCESS = 'Success';

  public static readonly SMART_CHARGING_MIN_DEPARTURE_TIME_SECS = 8 * 60 * 60;
  public static readonly SMART_CHARGING_MAX_DEPARTURE_TIME_SECS = (24 * 60 * 60) - 1;

  /* Data Service */
  public static readonly DEFAULT_LIMIT = Constants.DEFAULT_PAGE_SIZE;
  public static readonly NO_SKIP = 0;
  public static readonly FIRST_ITEM_PAGING = {limit: 1, skip: Constants.NO_SKIP};
  public static readonly DEFAULT_PAGING = {limit: Constants.DEFAULT_LIMIT, skip: Constants.NO_SKIP};
  public static readonly MAX_PAGING = {limit: Constants.MAX_PAGE_SIZE, skip: Constants.NO_SKIP};

  public static readonly ONLY_RECORD_COUNT_PARAM = { OnlyRecordCount: 'true' };

  public static readonly USER_NO_PICTURE = 'assets/img/theme/no-photo.png';
  public static readonly NO_IMAGE = 'assets/img/theme/no-image.png';
  public static readonly MASTER_TENANT_LOGO = '/assets/img/logo.png';

  /* RegEx validation rule */
  public static readonly REGEX_VALIDATION_LATITUDE = /^-?([1-8]?[1-9]|[1-9]0)\.{0,1}[0-9]*$/;
  public static readonly REGEX_VALIDATION_LONGITUDE = /^-?([1]?[0-7][0-9]|[1]?[0-8][0]|[1-9]?[0-9])\.{0,1}[0-9]*$/;
  public static readonly REGEX_VALIDATION_FLOAT = /^((\d+(\.\d+)?))$/;
  public static readonly REGEX_VALIDATION_NUMBER = /^\d+$/;

  /* Language and Locales */
  /* Make sure to maintain app.module.ts ==> e.g.: registerLocaleData(localeEnAU); */
  public static readonly SUPPORTED_LOCALES = Object.freeze(['en_US', 'fr_FR', 'es_ES', 'de_DE', 'pt_PT', 'it_IT', 'cs_CZ', 'en_AU']);
  public static readonly SUPPORTED_LANGUAGES = Object.freeze(['en', 'fr', 'es', 'de', 'pt', 'it', 'cs']);
  public static readonly DEFAULT_LOCALE = 'en_US';
  public static readonly DEFAULT_LANGUAGE = 'en';

  /* Default Currency */
  public static readonly DEFAULT_CURRENCY_CODE = 'EUR';

  public static readonly SELECT_ALL = 'A';

  /* App Info for STRIPE */
  public static readonly STRIPE_APP_NAME = 'Voltau';
  public static readonly STRIPE_PARTNER_ID = 'TECH-000685';
}
