import { Injectable } from '@angular/core';

import { Action, Entity } from '../types/Authorization';
import { UserRole, UserToken } from '../types/User';
import { CentralServerService } from './central-server.service';

@Injectable()
export class AuthorizationService {
  private loggedUser!: UserToken | null;

  public constructor(
    private centralServerService: CentralServerService) {
    this.centralServerService.getCurrentUserSubject().subscribe((user) => {
      this.loggedUser = user;
    });
  }

  public cleanUserAndUserAuthorization() {
    this.loggedUser = null;
  }

  public canListCars(): boolean {
    return this.canAccess(Entity.CAR, Action.LIST);
  }

  public canListCarCatalogs(): boolean {
    return this.canAccess(Entity.CAR_CATALOG, Action.LIST);
  }

  public canListTransfers(): boolean {
    return this.canAccess(Entity.BILLING_TRANSFER, Action.LIST);
  }

  public canListInvoices(): boolean {
    return this.canAccess(Entity.INVOICE, Action.LIST);
  }

  public canListChargingStations(): boolean {
    return this.canAccess(Entity.CHARGING_STATION, Action.LIST);
  }

  public canListChargingStationsInError(): boolean {
    return this.canAccess(Entity.CHARGING_STATION, Action.IN_ERROR);
  }

  public canListChargingStationsPlanning(): boolean {
    return this.canAccess(Entity.PLANNING, Action.LIST);
  }

  public canListAssets(): boolean {
    return this.canAccess(Entity.ASSET, Action.LIST);
  }

  public canListAssetsInError(): boolean {
    return this.canAccess(Entity.ASSET, Action.IN_ERROR);
  }

  public canListChargingProfiles(): boolean {
    return this.canAccess(Entity.CHARGING_PROFILE, Action.LIST);
  }

  public canListCompanies(): boolean {
    return this.canAccess(Entity.COMPANY, Action.LIST);
  }

  public canListSites(): boolean {
    return this.canAccess(Entity.SITE, Action.LIST);
  }

  public canListSiteAreas(): boolean {
    return this.canAccess(Entity.SITE_AREA, Action.LIST);
  }

  public canListUsers(): boolean {
    return this.canAccess(Entity.USER, Action.LIST);
  }

  public canListSubscriptions(): boolean {
    return this.canAccess(Entity.SUBSCRIPTION, Action.LIST);
  }

  public canListUsersInError(): boolean {
    return this.canAccess(Entity.USER, Action.IN_ERROR);
  }

  public canListUserGroups(): boolean {
    return this.canAccess(Entity.USER_GROUP, Action.LIST);
  }

  public canListTags(): boolean {
    return this.canAccess(Entity.TAG, Action.LIST);
  }

  public canRefundTransaction(): boolean {
    return this.canAccess(Entity.TRANSACTION, Action.REFUND_TRANSACTION);
  }

  public canListTransactions(): boolean {
    return this.canAccess(Entity.TRANSACTION, Action.LIST);
  }

  public canListTransactionsInError(): boolean {
    return this.canAccess(Entity.TRANSACTION, Action.IN_ERROR);
  }

  public canListTokens(): boolean {
    return this.canAccess(Entity.REGISTRATION_TOKEN, Action.LIST);
  }

  public isAdmin(): boolean {
    if (this.loggedUser) {
      return this.loggedUser.role === UserRole.ADMIN;
    }
    return false;
  }

  public isSuperAdmin(): boolean {
    if (this.loggedUser) {
      return this.loggedUser.role === UserRole.SUPER_ADMIN;
    }
    return false;
  }

  public canAccess(resource: string, action: string): boolean {
    return !!this.loggedUser && !!this.loggedUser.scopes && this.loggedUser.scopes.includes(`${resource}:${action}`);
  }
}
